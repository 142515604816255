@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-family: Inter, sans-serif;
  font-feature-settings: 'liga' 1, 'calt' 1; /* fix for Chrome */
}
@supports (font-variation-settings: normal) {
  :root { font-family: InterVariable, sans-serif; }
}

.text {
  @apply text-sm text-center mt-0 mb-2.5 lg:w-2/3 w-full;
}

.btn {
  @apply my-3.5 text-center transition-all ease-in-out duration-300 text-irrgardless font-bold border-2 border-irrgardless focus-visible:outline-none block p-2.5;
}

.btn:hover {
  @apply hover:text-white hover:border-irrgardless-red hover:bg-irrgardless-red;
}
